.footer {
  font-size: small;
  height: 60px;
  width: 100%;
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 12%);
  color: #3c3c3c;
  text-align: center;

  p {
    margin-top: 20px;
  }

  a {
    text-decoration: none;
    color: #0000ff;
  }
}
