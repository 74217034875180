.searchField {
	display: inline-flex;
	height: 40px;
	border-radius: 20px;
	background: #f8f8f8;
	border: solid 1px #e2e2e2;
	position: relative;
	overflow: hidden;
	align-items: center;
	padding: 0 15px;
	width: 100%;

	input {
		background: none;
		border: none;
		padding: 0 20px 0 35px;
		height: 100%;
	}
	input:focus {
		outline: none;
	}
	.icons {
		position: absolute;
		left: 12px;
		color: #7c7c7c;
	}
}
