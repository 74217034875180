.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    z-index: 10;

    .inner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;

        .content {
            width: 100%;
            max-height: 100%;
            overflow-y: auto;
            position: absolute;
            padding: 20px;
        }
    }
}

.box {
    background: #fff;
    margin: 0 auto;
    max-width: 900px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 12%);
    padding: 25px;
    position: relative;

    .close {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3c3c3c;
        transition: background .2s ease;
        cursor: pointer;

        &:hover {
            background: #f1f1f1;
        }
    }

    h3 {
        color: #252525;
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 20px;
    }

    .errorMessage {
      background: #FF4343;
      padding: 10px;
      border-radius: 5px;
      color: white;
      width: 100%;
      text-align: center;
      font-size: 15px;
      margin-bottom: 20px;
    }

    .successMessage {
      background: #10893E;
      padding: 10px;
      border-radius: 5px;
      color: white;
      width: 100%;
      text-align: center;
      font-size: 15px;
      margin-bottom: 20px;
    }

    .inputField {
        width: 100%;
        margin-bottom: 20px;
        display: flex;

        label {
            flex: 1;
        
            & > span {
                color: #252525;
                font-size: 14px;
                margin-bottom: 5px;
                font-weight: 500;
                display: block;
                padding-left: 5px;
            }

            & + label {
                margin-left: 20px;
            }

            &.textInput {
                & > input, textarea {
                    height: 40px;
                    background: white;
                    border: solid 1px #e2e2e2;
                    border-radius: 3px;
                    width: 100%;
                    padding: 0 15px;
                }

                & > textarea {
                    height: auto;
                    min-height: 100px;
                    resize: vertical;
                    padding: 15px;
                    font-family: inherit;
                } 
            }

            &.profilePicture {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                max-width: 150px;

                div {
                    height: 150px;
                    width: 150px;
                    border-radius: 75px;
                    overflow: hidden;
                    position: relative;
                    margin-top: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }

                    input {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 150px;
                        width: 150px;
                        opacity: 0;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .submitField {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
        
        button {
			padding: 0 35px;
			height: 45px;
			background-color: #1ead68;
			border: solid 1px #e2e2e2;
			border-radius: 51px;
			color: white;
			font-size: 20px;
			cursor: pointer;
			transition: background 0.2s ease, opacity 0.2s ease;

			&:hover {
				background: darken(#1ead68, 5%);
			}
        }
    }
}