/* profileCard */

.profileCard {
	padding: 0 25px;

	.profile {
		display: flex;
		align-items: center;
		height: 150px;
		cursor: pointer;
		transition: background 0.2s ease;

		.profileName {
			display: flex;
			flex-direction: column;
			font-size: larger;
			padding: 0 15px;
		}
	}

	.profileImage {
		img {
			height: 80px;
			width: 80px;
			border-radius: 50%;
			object-fit: cover;
			object-position: center;
		}
	}

	.info {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.stat + .stat {
			margin-left: 15px;
		}
	}
}

.footer {
	margin-top: 30px;
	border-top: solid 1px #e2e2e2;
	padding-top: 30px;
	color: #3c3c3c;
	font-size: small;

	p {
		margin-top: 15px;
	}

	a {
		text-decoration: none;
		color: #1EAD68;
	}
}
