.post {
	color: #3c3c3c;
	border: #e2e2e2 solid 2px;
	border-radius: 5px;
	padding: 30px 50px;
	margin-top: 10px;

	.userInfo {
		display: flex;
		align-items: center;
		margin-bottom: 15px;

		.profileImage {
			display: inline;
			vertical-align: middle;

			img {
				height: 60px;
				width: 60px;
				border-radius: 50%;
				object-fit: cover;
			}
		}

		.profileName {
			display: flex;
			flex-direction: column;
			margin-left: 15px;
			flex: 1;

			a {
				color: #8c8c8c;
				text-decoration: none;
				font-size: smaller;
			}

			a:hover {
				color: #3c3c3c;
			}
		}

		.right {
			display: flex;
			align-items: center;

			.postTime {
				display: inline;
				font-size: small;
				text-decoration: none;
				color: #3c3c3c;
			}

			.delete {
				color: #a7a7a7;
				cursor: pointer;
				margin-left: 10px;
				transition: color 0.2s ease;

				&:hover {
					color: #3c3c3c;
				}
			}
		}
	}

	.postMessage {
		b {
			display: block;
			margin-bottom: 10px;

			a {
				color: #1ead68;
				text-decoration: none;
			}
		}

		.text {
			margin-bottom: 30px;
		}

		.postImages {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			margin: -5px 0 20px;

			.postImage {
				flex: 1;
				min-width: 250px;
				margin: 5px;
				height: 220px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 5px;
				}
			}

			&.largeImages .postImage {
				height: auto;

				img {
					height: auto;
				}
			}
		}
	}

	.commentMessage {
		p {
			padding: 10px 30px;
		}

		a {
			color: #8c8c8c;
			text-decoration: none;
		}

		a:hover {
			color: #3c3c3c;
		}
	}

	.postActions {
		display: flex;
		align-items: center;
		margin-top: 30px;

		.action {
			flex: 1;
			font-size: 25px;
			color: #e2e2e2;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: color 0.2s ease;

			&:hover {
				color: #3c3c3c;
				cursor: pointer;
			}

			&.like {
				&:hover {
					color: #ff434367;
				}

				&.active {
					color: #ff4343;
				}
			}

			span {
				color: #7c7c7c;
				font-size: 18px;
				margin-left: 10px;
				font-weight: 600;
			}

			&.right {
				justify-content: flex-end;
			}
		}

		.postStatistics {
			display: inline;
			margin: 0 80px;
			font-size: large;
			color: #3c3c3c;
		}
	}
}

@media (max-width: 550px) {
	.post {
		padding: 20px 30px;

		.postActions {
			margin-top: 20px;

			.action {
				font-size: 20px;

				span {
					font-size: 15px;
				}
			}
		}
	}
}

@media (max-width: 480px) {
	.post .userInfo {
		flex-wrap: wrap;

		.right {
			min-width: 100%;
			margin-top: 20px;
		}
	}
}
