.content {
	display: flex;
	margin: 25px auto 15px;
	max-width: 1300px;
	padding: 0 15px;

	.profileContent {
		flex: 2;
		padding: 0 15px;
	}

	.suggestionContent {
		flex: 1;
		padding: 0 15px;
		max-width: 500px;

		.sticky {
			position: sticky;
			top: 85px;
		}
	}
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
	margin: 0 auto;
	max-width: 1500px;
    font-size: 40px;
    min-height: 300px;
    flex-direction: column;
    color: #1ead68;

    span {
        font-size: 20px;
        color: #3c3c3c;
        margin-top: 20px;
    }
}

.background {
	height: 200px;
	background-image: url(../../images/profile-background.png);
	background-size: 100px;
	border: 5px;
	border-radius: 15px;
}

.profile {
	transform: translateY(-30px);
	padding: 0 25px;

	.profileDetails {
		.profileName {
			display: flex;
			flex-direction: column;
			font-size: larger;
			margin-bottom: 20px;
			padding: 0 10px;
		}

		.stats {
			display: flex;

			div {
				margin: 0 10px;
			}
		}

		.description {
			margin-top: 10px;
			margin-bottom: 30px;
			padding: 0 10px;
		}
	}

	.profileRow {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		.profileImage {
			border: solid 2px white;
			height: 100px;
			width: 100px;
			border-radius: 50%;
			overflow: hidden;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		.spacer {
			flex: 1;
		}

		button {
			padding: 0 20px;
			height: 40px;
			background-color: #1ead68;
			border: none;
			border-radius: 20px;
			color: white;
			font-size: 18px;
			cursor: pointer;
			margin-top: 30px;
			transition: background 0.2s ease, opacity 0.2s ease;

			&:hover {
				background: darken(#1ead68, 5%);
			}
		}
	}
}

.noResults {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 80px 20px;

	img {
		height: 160px;
		width: 160px;
		border-radius: 80px;
		object-fit: cover;
		object-position: center;
	}

	span {
		font-size: 25px;
		color: #3c3c3c;
		margin-top: 40px;
		text-align: center;
	}
}

.loadingPosts {
	display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    min-height: 150px;
    flex-direction: column;
    color: #1ead68;

    span {
        font-size: 15px;
        color: #3c3c3c;
        margin-top: 20px;
    }
}

@media (max-width: 1100px) {
	.content {
		flex-direction: column-reverse;
		align-items: center;
		width: 100%;
		
		.profileContent {
			width: 100%;
			padding: 0;
		}
	
		.suggestionContent {
			width: 100%;
			padding: 0;
			margin-bottom: 25px;
	
			.sticky .suggestions {
				display: none;
			}
		}
	}

	.profile .profileDetails .stats {
		flex-wrap: wrap;

		div {
			white-space: nowrap;
			margin: 10px;
		}
	}
}

@media (max-width: 500px) {
	.profile {
		padding: 0 15px;
	}
}

@media (max-width: 450px) {
	.profile .profileRow {
		flex-direction: column;
		align-items: center;

		button {
			margin-top: 20px;
		}
	}
}