.page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .aboutUsPage {
        flex: 1;
        color: #3c3c3c;
        padding: 0 15px;
        margin-bottom: 15px;
    }
}

.aboutPics {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.aboutPics img {
	border-radius: 50%;
	object-fit: cover;
	height: 250px;
	width: 250px;
	margin: 20px;
}

.aboutUsHeading {
	text-align: center;
	color: #1ead68;
}

.aboutUsText {
	text-align: center;
	color: #3c3c3c;
}

.returnHolder {
	margin-top: 40px;
	display: flex;
	justify-content: center;

	a {
		display: flex;
		align-items: center;
		padding: 0 28px;
		height: 56px;
		background-color: #1ead68;
		border: solid 1px #e2e2e2;
		border-radius: 51px;
		color: white;
		font-size: 16px;
		cursor: pointer;
		text-decoration: none;
		transition: background 0.2s ease, opacity 0.2s ease;

		&:hover {
			background: darken(#1ead68, 5%);
		}
	}
}
