.content {
	display: flex;
	margin: 0 auto 15px;
	max-width: 1300px;
	padding: 0 15px;

	.leftContent {
		flex: 2;
		padding: 0 15px;
	
		&.extraPadding {
			padding-top: 15px;
		}
	}
	
	.rightContent {
		flex: 1;
		padding: 25px 15px 0;
		max-width: 500px;
	
		.sticky {
			position: sticky;
			top: 85px;
		}
	}
}

.noResults {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 80px 20px;

	img {
		height: 160px;
		width: 160px;
		border-radius: 80px;
		object-fit: cover;
		object-position: center;
	}

	span {
		font-size: 25px;
		color: #3c3c3c;
		margin-top: 40px;
		text-align: center;
	}
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
	margin: 0 auto;
	max-width: 1500px;
    font-size: 40px;
    min-height: 300px;
    flex-direction: column;
    color: #1ead68;

    span {
        font-size: 20px;
        color: #3c3c3c;
        margin-top: 20px;
    }
}

.loadingPosts {
	display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    min-height: 150px;
    flex-direction: column;
    color: #1ead68;

    span {
        font-size: 15px;
        color: #3c3c3c;
        margin-top: 20px;
    }
}

@media (max-width: 1100px) {
	.content {
		flex-direction: column-reverse;
		align-items: center;
		width: 100%;
		
		.leftContent {
			width: 100%;
		}

		.rightContent {
			width: 100%;

			.sticky .profileCard {
				display: none;
			}
		}
	}
}