.Imprint{

	h1{
		color: #1ead68;
		text-align: center;
	}

	p{
		padding-left: 10%;
		padding-right: 10%;
		padding-bottom: 10px;
		text-align:justify;
	}

}

.Privacy{

	h1{
		color: #1ead68;
		text-align: center;
	}

	h4{
		text-align: center;
	}

	p{
		padding-left: 10%;
		padding-right: 10%;
		padding-bottom: 10px;
		text-align:justify;
	}

}